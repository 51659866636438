/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Location } from "@reach/router"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      backgroundImg: file(name: {eq: "kakoon-background-compressed"}) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 4000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Location>
      {({ location }) => {
        console.log(location.pathname)
        let pageSlug = "home page-slug";
        if(location.pathname.includes("about-us")){
          pageSlug = "about-us page-slug"
        }else if(location.pathname.includes("product")){
          pageSlug = "product page-slug"
        }else if(location.pathname.includes("contact")){
          pageSlug = "contact page-slug"
        }
        return (
          <BackgroundImage className={pageSlug +" page-wrapper"} 
            fluid={data.backgroundImg.childImageSharp.fluid}
            style={{
              height: "100vh"
            }}
          >
            
            <Header headerClass={pageSlug} />
            <main>{children}</main>
            <footer className="footer">
              <div className="footer__container row">
                <div className="top-section column">
                  <span>Trading as Kakoon PTY LTD</span>
                  <span style={{margin: "0 10px"}}>•</span>
                  <span>ABN 92622309835</span>
                  <span style={{margin: "0 10px"}}>•</span>
                  <span><a href="mailto:enquiries@kakoon.com.au" target="blank">enquiries@kakoon.com.au</a></span>
                  <span style={{margin: "0 10px"}}>•</span>
                  <span><a href="tel:+61419532274" target="blank">+61 419 532 274</a></span>
                </div>

                <div className="bottom-section column">
                  <span className="company-name">Kakoon Digital Rewards</span>
                  <span style={{margin: "0 10px"}}>•</span>
                  <span>Website developed by <a href="https://dailypress.com.au/">Daily Press</a></span>
                </div>
              </div>
            </footer>
            
          </BackgroundImage>
        )
      }
    }
    </Location>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
