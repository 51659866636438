import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from 'react-helmet'

import favicon from '../images/icon.png'
import logo from '../images/kakoon-logo-white-compressed.png'
const Header = ({headerClass}) => (
  <header
    style={{
      background: `transparent`,
    }}
  >
    <Helmet
	link={[
		{rel: 'shortcut icon', type: 'image/png', href: `${favicon}`}
	]}
    />
    <nav className={"header row " + headerClass}>
        <div className="left-section">
            <Link to="/">
                <img src={logo} alt="Kakoon Digital Rewards Logo" />
            </Link>
        </div>
        <div className="right-section">
            {/* <div className="dp-custom-menu" dangerouslySetInnerHTML={{__html: headerData}}>
            </div> */}
            <Link to="/about-us" className="about-us"><strong>ABOUT US</strong></Link>
            <Link to="/product" className="product"><strong>PRODUCT</strong></Link>
            <Link to="/contact" className="contact"><strong>CONTACT</strong></Link>
        </div>
        <div className="right-section-mobile">
          <div className="hamburger-icon">
            <span className="line line-1"></span>
            <span className="line line-2"></span>
            <span className="line line-3"></span>
          </div>
          <div className="mobile-menu">
            <Link to="/about-us" className="about-us"><strong>ABOUT US</strong></Link>
            <Link to="/product" className="product"><strong>PRODUCT</strong></Link>
            <Link to="/contact" className="contact"><strong>CONTACT</strong></Link>
            
          </div>
        </div>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
